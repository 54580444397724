import customAxios from ".././utils/customAxios";

// get address by search word
export const getLocationByPlaceid = async (placeid) => {
  try {
    const res = await customAxios.get(
      `/api/admin/googleapi/geocode/${placeid}`
    );
    // console.log(res.data.results[0]);
    // return res.data.results[0].geometry.location;
    return res.data.results[0];
  } catch (err) {
    return [];
  }
};

export const getLocationByAddress = async (placeid, country) => {
  try {
    if(placeid === "") return null;

    const res = await customAxios.get(
      `/api/admin/googleapi/geocode-address/${placeid}/${country}`
    );
    // console.log(res.data.results[0]);
    // return res.data.results[0].geometry.location;
    return res.data.results[0];
  } catch (err) {
    return [];
  }
};

// get address by search word
export const getAutocompleteAddresses = async (word) => {
  try {
    const res = await customAxios.get(
      `/api/admin/googleapi/autocomplete/${word}`
    );
    // console.table(res);
    return res.data.predictions;
  } catch (err) {
    return [];
  }
};

// translate word
export const translate = async (lang, text) => {
  try {
    const res = await customAxios.post(
      `/api/admin/googleapi/translation`,
      {
        target: lang,
        text: text
      }
    );
    // console.table(res);
    return res.data.data.translations[0];
  } catch (err) {
    return [];
  }
};
