import { useTranslation } from "react-i18next";
import React, { useState, useEffect, Fragment } from "react";

import {
  fetchAccountInfo,
  updateIndividualBillingInformation,
  updatePaymentMethod
} from "../../../../actions/profile";

import {
  flywireAddPaymentMethod,
} from "../../../../actions/admin/flywire";
import { getLocationByAddress } from "../../../../actions/googleAPI";

import visa_icon from "../../../../icons/visa.svg";
import master_card_icon from "../../../../icons/master-card.svg";
import jcb_icon from "../../../../icons/jcb.svg";
import american_ex_icon from "../../../../icons/american_ex.svg";

import email_icon from "../../../../icons/email.svg";

import countries from "../../../../assets/json/countries.json";

import { useLoading } from "../../LoadingContext";
import { useHistory } from "react-router-dom";

const AccountInformation = () => {
  const { t } = useTranslation();
  const { showLoading, closeLoading } = useLoading();

  const [idIndividualDetail, setIdIndividualDetail] = useState();
  const [email, setemail] = useState("");
  const [billingInformation, setBillingInformation] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);

  const [countriesData] = useState(countries);
  const [countryCode, setCountryCode] = useState("JP");
  const [prefixPhoneNumber, setPrefixPhoneNumber] = useState("81");
  const [pronounced, setPronounced] = useState("Mr.");

  const [searchPhoneCode, setSearchPhoneCode] = useState(null);

  const history = useHistory();

  const handleLiClickCountryList = (dialCode, countryCode, name) => {
    setPrefixPhoneNumber(dialCode);
    setCountryCode(countryCode);
    setBillingInformation({
      ...billingInformation,
      ['prefix_number']: dialCode,
      ['country_code']: countryCode
    });
  };

  // Function to handle pronounced list clicked
  const handleLiClickPronouncedList = (pronounced) => {
    setPronounced(pronounced);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchAccountInfo();

      console.log(data)
      console.log("===============000")

      // email
      setemail(data.email);
      setIdIndividualDetail(data._id);
      setBillingInformation(data.individualDetail?.billingInformation);
      setPrefixPhoneNumber(data.individualDetail?.billingInformation.prefix_number);
      setCountryCode(data.individualDetail?.billingInformation.country_code);
      setPronounced(data.individualDetail?.billingInformation.pronounced);

      // payment methods
      setPaymentMethods(data.individualDetail.paymentMethod);
    };
    fetchData();
  }, []);

  const putupdateIndividualBillingInformation = async () => {
    await updateIndividualBillingInformation(billingInformation);
  };

  const handleUpdateBillingInformation = () => {
    putupdateIndividualBillingInformation();
  };


  function getCardTypeByBrand(type) {

    if (type === "VISA") {
      return visa_icon;
    } else if (type === "MASTERCARD") {
      return master_card_icon;
    } else if (type === "AMERICAN EXPRESS") {
      return american_ex_icon;
    } else if (type === "JCB") {
      return jcb_icon;
    }

    return "Unknown"; // If none of the patterns match
  }

  function convertToMMYYYY(input) {
    // Check if the input string has a length of 4 and contains only digits
    if (/^\d{4}$/.test(input)) {
      const month = input.slice(0, 2);
      const year = "20" + input.slice(2);
      return `${month}/${year}`;
    } else {
      // Handle invalid input
      return "Invalid input";
    }
  }

  const handleBillingInformation = (e) => {
    let value = e.target.value;
    setBillingInformation({
      ...billingInformation,
      [e.target.name]: value,
    });
  };

  const handleLogout = () => {
    showLoading(true);
    localStorage.removeItem("token");
    closeLoading(true);
    window.location = "/";
  };

  const handleUpdatePaymentMethods = async (id, paymentMethodId, action) => {
    var newPaymentMethods = paymentMethods;
    switch (action) {
      case "activate":
        newPaymentMethods.forEach(method => {
          if (method._id === paymentMethodId) {
            method.isActive = true;
          } else {
            method.isActive = false;
          }
        });
        var res = await updatePaymentMethod(id, newPaymentMethods);
        if (res.response_code == 200 || res.response_code == 201) {
          setPaymentMethods(res.body.paymentMethod);
        }
        break;
      case "delete":
        newPaymentMethods = newPaymentMethods.filter(item => item._id !== paymentMethodId);
        var res = await updatePaymentMethod(id, newPaymentMethods);
        if (res.response_code == 200 || res.response_code == 201) {
          setPaymentMethods(res.body.paymentMethod);
        }
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    console.log('paymentMethods updated:', paymentMethods);
  }, [paymentMethods])

  const callbackFlywireAddPaymentMethod = async (result) => {
    if (result.status === "success") {
      const updatePayment = await flywireAddPaymentMethod(result);

      console.log(updatePayment);
      if (updatePayment.status === "SUCCESS") history.go();

    }
  };

  const extractFirstAndLastName = async (fullName) => {
    // Split the full name into an array of strings
    const nameParts = fullName.trim().split(' ');

    // Extract the first name (first element of the array)
    const firstName = nameParts[0];

    // Extract the last name (last element of the array, if exists)
    const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';

    // Return an object containing the first name and last name
    return {
      firstName: firstName,
      lastName: lastName ? lastName : firstName,
    };
  }

  const addPaymentMethodHandler = async () => {
    const { firstName, lastName } = await extractFirstAndLastName(
      billingInformation.fullName
    );
    const zipCodeCheck = await getLocationByAddress(
      billingInformation.postCode,
      billingInformation.country_code
    );

    const config = {
      env: process.env.REACT_APP_FLYWIRE_ENV,
      recipientCode: process.env.REACT_APP_FLYWIRE_TLF_RECIPIENT_CODE,

      // >0 for Seed Payment and Optional Tokenization; 0 for Zero-Value Tokenization;
      amount: 0,
      //Mandatory tokenization before payment - "tokenization" or "optional_tokenization";
      recurringType: "tokenization",
      recurringTypeRecipientCodes: [
        process.env.REACT_APP_FLYWIRE_TLF_RECIPIENT_CODE,
        process.env.REACT_APP_FLYWIRE_JPT_RECIPIENT_CODE,
      ],

      // Specify the returnUrl or an onCompleteCallback handler
      // returnUrl: process.env.REACT_APP_URL + "/PaymentSuccess/"+ cart_id,

      // Other checkout parameters (e.g. pass payer info or set requestPayerInfo to true)
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: billingInformation.prefix_number + " " + billingInformation.phoneNumber,
      address: billingInformation.address,
      city: zipCodeCheck.address_components.filter(
        (item) =>
          item.types.includes("administrative_area_level_2") ||
          item.types.includes("locality")
      )[0].long_name,
      zip: billingInformation.postCode,
      country: billingInformation.country_code,

      requestPayerInfo: false,
      requestRecipientInfo: false,

      // Control which tokenization options are available
      paymentOptionsConfig: {
        filters: {
          type: ["credit_card"],
        },
      },
      onCompleteCallback: function (args) {
        callbackFlywireAddPaymentMethod(args);
      },
      onInvalidInput: function (errors) {
        errors.forEach(function (error) {
          console.log(error.msg);
        });
      },
    };

    const modal = window.FlywirePayment.initiate(config);
    modal.render();
  };

  return (
    <>
      <div className="card mt-3 border-gold">
        <div className="card-body">
          <div className="py-3 px-2">
            <h5 className="mb-3">  {t('my_page.account_page.email_address')}</h5>
            <div className="row g-3 mb-3">
              <div className="col form-floating fs-8">
                <input
                  type="text"
                  className={`form-control contact-form `}
                  id="emailAddress"
                  placeholder="Email address"
                  name="email"
                  defaultValue={email}
                />
                <label htmlFor="emailAddress">
                  <div className="d-flex align-items-center">
                    <img alt="img" src={email_icon} />
                    &nbsp;  {t('my_page.account_page.email_address')}
                  </div>
                </label>
              </div>
            </div>
            <div className="d-grid col-md-3">
              <button
                type="button"
                className="btn bg-gold-light-100 px-5 py-2 mb-3 rounded"
              >
                {t('my_page.account_page.change_email_address')}
              </button>
            </div>
            <h5 className="mb-3 mt-4">{t('my_page.account_page.password')}</h5>

            <div className="d-grid col-md-3">
              <button
                type="button"
                className="btn bg-gold-light-100 px-5 py-2 mb-3 rounded"
              >
                {t('my_page.account_page.change_password')}
              </button>
            </div>
            <h5 className="mb-3 mt-4"> {t('my_page.account_page.billing_information')}</h5>
            <div className="row g-3 mb-3">
              <div className="col-md-6">
                <div className="input-group">
                  <button
                    className="btn bg-gold-light-100 fw-100 rounded dropdown-toggle p"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {pronounced}
                  </button>
                  <ul className="dropdown-menu">
                    <li
                      className="dropdown-item "
                      onClick={() => handleLiClickPronouncedList("Mr.")}
                    >
                      Mr.
                    </li>
                    <li
                      className="dropdown-item "
                      onClick={() => handleLiClickPronouncedList("Ms.")}
                    >
                      Ms.
                    </li>
                    <li
                      className="dropdown-item "
                      onClick={() => handleLiClickPronouncedList("Mrs.")}
                    >
                      Mrs.
                    </li>
                  </ul>

                  <input
                    type="text"
                    name="fullName"
                    className={`form-control contact-form p-3`}
                    placeholder={t("Passenger Name")}
                    aria-label={t("Passenger Name")}
                    value={billingInformation.fullName}
                    onChange={(e) => {
                      handleBillingInformation(e);
                    }}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="input-group fs-8 ">
                  <button
                    className="btn bg-gold-light-100 fw-100 rounded noto dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={`https://flagsapi.com/${billingInformation.country_code}/flat/24.png`}
                    />{" "}
                    ({billingInformation.prefix_number})
                  </button>
                  <ul className="dropdown-menu">
                    <div className="px-1 align-items-center mb-3 dropdown-search">
                      <input
                        type="text"
                        className={`form-control p-3 mb-2`}
                        defaultValue={searchPhoneCode}
                        placeholder={t("SelectCountry.Placeholder")}
                        onChange={(e) => setSearchPhoneCode(e.target.value)}
                        autoComplete={"new-password"}
                      />
                    </div>

                    {
                      searchPhoneCode !== null ?
                        countriesData
                          ?.filter((item) =>
                            item.name
                              .toLowerCase()
                              .includes(
                                searchPhoneCode !== null
                                  ? searchPhoneCode.toLowerCase()
                                  : null
                              )
                          )
                          ?.map((country, index) => (
                            <Fragment key={country.code}>
                              <li
                                className="dropdown-item m-0"
                                onClick={() =>
                                  handleLiClickCountryList(
                                    country.dial_code,
                                    country.code,
                                    country.name
                                  )
                                }
                              >
                                <div className="row px-1 align-items-center">
                                  <div className="col-auto">
                                    <div className="bg-secondary bg-opacity-25 px-1">
                                      <img
                                        src={`https://flagsapi.com/${country.code}/flat/24.png`}
                                        alt={country.name}
                                      />
                                    </div>
                                  </div>
                                  <div className="col">
                                    ({country.dial_code}) {country.name}
                                  </div>
                                </div>
                              </li>
                              <hr className="my-1" />
                            </Fragment>
                          )) :
                        countriesData
                          ?.map((country, index) => (
                            <Fragment key={country.code}>
                              <li
                                className="dropdown-item m-0"
                                onClick={() =>
                                  handleLiClickCountryList(
                                    country.dial_code,
                                    country.code,
                                    country.name
                                  )
                                }
                              >
                                <div className="row px-1 align-items-center">
                                  <div className="col-auto">
                                    <div className="bg-secondary bg-opacity-25 px-1">
                                      <img
                                        src={`https://flagsapi.com/${country.code}/flat/24.png`}
                                        alt={country.name}
                                      />
                                    </div>
                                  </div>
                                  <div className="col">
                                    ({country.dial_code}) {country.name}
                                  </div>
                                </div>
                              </li>
                              <hr className="my-1" />
                            </Fragment>
                          ))
                    }
                  </ul>
                  <input
                    type="number"
                    className={`form-control contact-form p-3 `}
                    id="phoneNumber"
                    placeholder={t("Phone.Number")}
                    name="phoneNumber"
                    value={billingInformation.phoneNumber}
                    onChange={(e) => {
                      handleBillingInformation(e);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="row g-3 mb-3">
              <div className="col form-floating fs-8">
                <input
                  type="text"
                  className={`form-control contact-form p-3`}
                  id="streetAddress"
                  placeholder="Address"
                  name="address"
                  value={billingInformation.address}
                  onChange={(e) => {
                    handleBillingInformation(e);
                  }}
                // onChange={handleChangeProfile}
                />
                <label htmlFor="streetAddress">{t("Address")}</label>
              </div>
            </div>

            <div className="row g-3 mb-3">
              <div className="col-md-6 form-floating fs-8">
                <input
                  type="number"
                  className={`form-control contact-form`}
                  id="postCode"
                  placeholder="Post code"
                  name="postCode"
                  value={billingInformation.postCode}
                  onChange={(e) => {
                    handleBillingInformation(e);
                  }}
                // onChange={handleChangeProfile}
                />
                <label htmlFor="postCode">{t("Post.Code")}</label>
              </div>

              <div className="col-md-6 form-floating fs-8">
                <input
                  type="text"
                  className={`form-control contact-form`}
                  id="country"
                  placeholder="Country"
                  name="country"
                  defaultValue={billingInformation.country}
                  onChange={(e) => {
                    handleBillingInformation(e);
                  }}
                />
                <label htmlFor="country">{t("Country")}</label>
              </div>
            </div>

            <div className="d-grid col-md-3">
              <button
                onClick={handleUpdateBillingInformation}
                type="button"
                className="btn bg-gold-light-100 px-5 py-2 mb-3 rounded"
              >
                {t('my_page.account_page.edit_billing_information')}
              </button>
            </div>

            <h5 className="mb-3 mt-4">
              {t('my_page.account_page.payment_method')}
            </h5>
            {paymentMethods.map((item, index) => (
              <div
                className="row bg-light my-3  py-3 px-3 justify-content-between payment-method-custom-border-bottom"
                key={index}
              >
                <div className="col">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="paymentMethod"
                      id={`paymentMethod${index}`}
                      checked={item.isActive}
                      onChange={async () => handleUpdatePaymentMethods(idIndividualDetail, item._id, "activate")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`paymentMethod${index}`}
                    >
                      <img
                        src={getCardTypeByBrand(item.brand)}
                        alt=""

                        className="me-2"
                      />
                      <span>
                        {"••••" +
                          item.cardNumber.slice(-4) +
                          ", Exp.: " +
                          convertToMMYYYY(item.mmyy)}
                      </span>
                    </label>
                  </div>
                </div>
                <div className="col-auto">
                  <button
                    type="button"
                    className="btn-close p-2"
                    aria-label="Close"
                    onClick={async () => handleUpdatePaymentMethods(idIndividualDetail, item._id, "delete")}
                  ></button>
                </div>
              </div>
            ))}

            <div className="d-grid col-md-3">
              <button
                type="button"
                className="btn bg-gold-light-100 px-5 py-2 mb-3 rounded"
                onClick={() => addPaymentMethodHandler()}
              >

                {t('my_page.account_page.add_payment_method')}
              </button>
            </div>


          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-3">
          <div className="d-grid gap-2">
            <button
              type="button"
              className="btn btn-primary mt-4 py-2 rounded"
              onClick={handleLogout}
            >
              Log Out
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountInformation;
